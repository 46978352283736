/* eslint-disable */
import { ILearnerRes, IUpdateLearnerData, LearnerAddRequest, LearnerState } from "./types";
export const SET_LEARNER = "SET_LEARNER";
export const SET_LEARNER_SKILL_SIGN = "SET_LEARNER_SKILL_SIGN";
export const LEARNER_LOADING = "LEARNER_LOADING";
export const LEARNER_ERROR = "LEARNER_ERROR";
export const SET_LEARNER_LIST = "SET_LEARNER_LIST"
export const LEARNER_DETAILS = 'LEARNER_DETAILS'
export const SET_UPDATE_LEARNER = 'SET_UPDATE_LEARNER'
export const RESET_FORM = 'RESET_FORM'

export const setForm = (learnerData: object) => ({
  type: RESET_FORM,
  payload: learnerData,
});

export const setLearner = (learnerData: ILearnerRes) => ({
  type: SET_LEARNER,
  payload: learnerData,
});
export const setLearnerSkillsSign = (learnerData: ILearnerRes) => ({
  type: SET_LEARNER_SKILL_SIGN,
  payload: learnerData,
});

export const setLearnerLoading = (loading: boolean) => ({
  type: LEARNER_LOADING,
  payload: loading,
});

export const setLearnerError = (error: string | null) => ({
  type: LEARNER_ERROR,
  payload: error,
});

export const setLearnerList = (learnerList : LearnerState) => ({
    type : SET_LEARNER_LIST,
    payload : learnerList
})


export const setLearnerDetails = (learnerDetails : LearnerState) => ({
  type : LEARNER_DETAILS,
  payload : learnerDetails
})

export const setUpdateLearner = (updateLearner : IUpdateLearnerData) => ({
  type : SET_UPDATE_LEARNER ,
  payload : updateLearner
})

import { BIL_COUNT, BIL_GET, BIL_LIST, setAddBill, setBilList } from "./action";
import { BilState } from "./types";

const initialState: BilState = {
    bilData: null,
    loading: false,
    error: null,
    bil_list : [],
    bil : null,
    bil_count : ''
};

const bilReducer = (state = initialState, action: any): BilState => {
   switch (action.type) {
     case BIL_LIST :
          return { ...state, bil_list: action.payload };
     case BIL_GET : 
         return { ...state , bil : action.payload} 
     case BIL_COUNT : 
         return { ...state , bil_count : action.payload}         
        default:
          return state;
  }
};

export default bilReducer;

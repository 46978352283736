
export const ADD_BIL =  "ADD_BIL"
export const BIL_LIST  = 'BIL_LIST'
export const BIL_GET  = 'BIL_GET'
export const BIL_COUNT = 'BIL_COUNT'

export const setAddBill = (add_bil : any) => ({
  type: ADD_BIL,
  payload : add_bil,  
})

export const setBilList = (bil_list : any) => ({
  type: BIL_LIST,
  payload : bil_list,  
})

export const setBil = (get_bil : any) => ({
  type: BIL_GET,
  payload : get_bil,  
})

export const setBilCount = (bil_count : any) => ({
  type: BIL_COUNT,
  payload : bil_count,  
})

// export const UpdateBilAction {
//   type: typeof UPDATE_BIL;
//   payload: IBilTypes; 
// }

// export const BilLoadingAction {
//   type: typeof BIL_LOADING;
//   payload: boolean;  
// }

// export const BilErrorAction {
//   type: typeof BIL_ERROR;
//   payload: any;  
// }

// export const AddAppointmentAction {
//     type: typeof ADD_APPOINTMENT;
//     payload: IAppointmentAddRequest; 
//   }
  

// export type BilActionTypes =
//   | AddBilAction
//   | UpdateBilAction
//   | BilLoadingAction
//   | BilErrorAction;

//   export type AppointmentActionTypes =
//   | AddAppointmentAction
import { SET_STORE_PROGRESS_LIST } from "./action";
import { IRequestInitialProgress } from "./types";


const initialState: IRequestInitialProgress = {
  progressList: {
    data: [],
    pagination: {
      current_page: 0,
      record_limit: 0,
      total_records: 0,
    },
  },
};

const progressReducer = (state = initialState, action: any): IRequestInitialProgress => {
  switch (action.type) {
    case SET_STORE_PROGRESS_LIST:
      return {
        ...state,
        progressList: action.payload,
      };
    default:
      return state;
  }
};

export default progressReducer;


export const ADD_WITHDRAW =  "ADD_WITHDRAW"
export const GET_WITHDRAW  = 'GET_WITHDRAW'

export const setAddBill = (add_withdraw : any) => ({
  type: ADD_WITHDRAW,
  payload : add_withdraw,  
})
export const setWithDraw = (get_withdraw : any) => ({
  type: GET_WITHDRAW,
  payload : get_withdraw,  
})

/* eslint-disable */
export const SET_MATH_RESULT = 'SET_MATH_RESULT';
export const SET_ENGLISH_RESULT = 'SET_ENGLISH_RESULT';
export const SET_UPDATE_ENGLISH_RESULT = 'SET_UPDATE_ENGLISH_RESULT';
export const SET_IGA_REPORT = 'SET_IGA_REPORT';
export const SET_PLR_REPORT = 'SET_PLR_REPORT';
export const  PROOF_OF_ID = 'PROOF_OF_ID';
export const PROOF_OF_EMPLOYMENT = 'PROOF_OF_EMPLOYMENT';
export const PROOF_OF_QUALIFICATION = 'PROOF_OF_QUALIFICATION';
export const REMOVE_MATH_RESULT = 'REMOVE_MATH_RESULT';
export const REMOVE_ENGLISH_RESULT = 'REMOVE_ENGLISH_RESULT';
export const SET_REMOVE_PLR_REPORT = 'SET_REMOVE_PLR_REPORT';
export const SET_REMOVE_IGA_REPORT = 'SET_REMOVE_IGA_REPORT';
export const SET_REMOVE_QUALIFICATION = 'SET_REMOVE_QUALIFICATION';
export const SET_REMOVE_PROOF_EMPLOYMENT = 'SET_REMOVE_PROOF_EMPLOYMENT';
export const SET_REMOVE_PROOF_ID = 'SET_REMOVE_PROOF_ID';
export const BIL_LEARNING_EVIDENCE = 'BIL_LEARNING_EVIDENCE'
export const BIL_REMOVE_LEARNING_EVIDENCE = 'BIL_REMOVE_LEARNING_EVIDENCE'
export const BIL_REMOVE = 'BIL_REMOVE'



export const setMathResult = (Fileupload : any) => ({
    type: SET_MATH_RESULT,
    payload: Fileupload,
    
})

export const setRemoveMathResult = (Fileupload : any) => ({
    type: REMOVE_MATH_RESULT,
    payload: Fileupload,
    
})

export const setRemoveEnglishResult = (Fileupload : any) => ({
    type: REMOVE_ENGLISH_RESULT,
    payload: Fileupload,
    
})

// iag_report

export const setEnglishResult = (Fileupload : any) => ({
    type: SET_ENGLISH_RESULT,
    payload: Fileupload
})


export const setUpdateEnglishResult = (Fileupload : any) => ({
    type: SET_UPDATE_ENGLISH_RESULT,
    payload: Fileupload
})
export const setIGAResult = (Fileupload : any) => ({
    type: SET_IGA_REPORT,
    payload: Fileupload
})

export const setPLRResult = (Fileupload : any) => ({
    type: SET_PLR_REPORT,
    payload: Fileupload
})

export const setRemoveIGAResult = (Fileupload : any) => ({
    type: SET_REMOVE_IGA_REPORT,
    payload: Fileupload
})

export const setRemovePLRResult = (Fileupload : any) => ({
    type: SET_REMOVE_PLR_REPORT,
    payload: Fileupload
})

export const setProofOfId = (Fileupload : any) => ({
    type: PROOF_OF_ID,
    payload: Fileupload
})

export const setProofOfEmployment= (Fileupload : any) => ({
    type: PROOF_OF_EMPLOYMENT,
    payload: Fileupload
})

export const setProofOfQualification= (Fileupload : any) => ({
    type: PROOF_OF_QUALIFICATION,
    payload: Fileupload
})


export const setRemoveProofOfId = (Fileupload : any) => ({
    type: SET_REMOVE_PROOF_ID,
    payload: Fileupload
})

export const setRemoveProofOfEmployment = (Fileupload : any) => ({
    type: SET_REMOVE_PROOF_EMPLOYMENT,
    payload: Fileupload
})

export const setRemoveProofOfQualification = (Fileupload : any) => ({
    type: SET_REMOVE_QUALIFICATION,
    payload: Fileupload
})

// bil

export const setBilLearningEvidence= (Fileupload : any) => ({
    type: BIL_LEARNING_EVIDENCE,
    payload: Fileupload
})

export const setRemoveBilEvidence= (Fileupload : any) => ({
    type: BIL_REMOVE_LEARNING_EVIDENCE,
    payload: Fileupload
})

export const setRemoveBil= (Fileupload : any) => ({
    type: BIL_REMOVE,
    payload: Fileupload
})
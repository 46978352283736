import { COMPLIANCE_DATE, COMPLIANCE_LIST, COMPLIANCE_RESPONSE } from "./action";
import { Iresponse } from "./types";


  
  const initialState: Iresponse = {
    complice_date:  null,
    compliance: [],
    compliance_list : null
  };
  
  const complianceReducer = (state = initialState, action: any): Iresponse => {
    switch (action.type) {
      case COMPLIANCE_DATE:
        return { ...state, complice_date: action.payload };
      case COMPLIANCE_RESPONSE:
        return { ...state, compliance: action.payload };
        case COMPLIANCE_LIST:
        return { ...state, compliance_list: action.payload };
      default:
        return state;
    }
  };
  
  export default complianceReducer;
  
import { IReviewList } from "./types";

export const SET_STORE_REVIEW_LIST = 'SET_STORE_REVIEW_LIST';
export const SET_STORE_REVIEW_GET = 'SET_STORE_REVIEW_GET'
 
export const setReviewList = (ReviewList : IReviewList) => ({
    type: SET_STORE_REVIEW_LIST,
    payload: ReviewList
})

export const setReview = (ReviewList : IReviewList) => ({
    type: SET_STORE_REVIEW_GET,
    payload: ReviewList
})

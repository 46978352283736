import { createSelector } from "reselect";
import { RootState } from "./store";
import complianceReducer from "./modules/admin/complince/reducer";

const selectCombinedReducer = (state: RootState) => state.combinedReducer;

export const selectPosterReducers = createSelector(
  [selectCombinedReducer],
  (combinedReducer) => ({
    ...combinedReducer?.request,
    ...combinedReducer?.learner,
    ...combinedReducer?.notification,
    ...combinedReducer?.review,
    ...combinedReducer?.OTJH,
    ...combinedReducer?.attendance,
    ...combinedReducer?.progress,
    user_data: combinedReducer?.user_data,
    programDetail: combinedReducer?.program?.programDetail,
    programClone: combinedReducer?.program?.cloneProgram,
    program: combinedReducer?.program?.data,
    program_complete_data: combinedReducer?.program?.complete_data,
    skillScan: combinedReducer?.program?.skillScan,
    skills: combinedReducer?.program?.skills,
    knowledge: combinedReducer?.program?.knowledge,
    behavior: combinedReducer?.program?.behavior,
    standard: combinedReducer?.program?.StandardDetail,
    learningPlan: combinedReducer?.program?.learningPlan,
    fees: combinedReducer?.program?.feesStructure,
    learnerDetails: combinedReducer?.learner?.learner,
    user: combinedReducer?.users,
    contact: combinedReducer?.contact?.contact,
    contactList: combinedReducer?.contact?.contactList,
    diploma: combinedReducer?.program?.diploma,
    review : combinedReducer?.review?.get_data,
    reviewList: combinedReducer?.review?.reviewList,
    attendanceList: combinedReducer?.attendance?.attendanceList,
    progressList: combinedReducer?.progress?.progressList,
    OTJHList: combinedReducer?.OTJH?.OTJHList,
    restForm: combinedReducer?.learner?.learnerForm,
    fileupload: combinedReducer?.fileupload,
    compliance: combinedReducer?.compliance,
    otherDocs:combinedReducer?.document?.otherDocs,
    initialAssessment:combinedReducer?.document?.initialDocs,
    complianceDoc:combinedReducer?.document?.complianceDocs,
    document_id : combinedReducer?.document?.document_id,
    learner_id : combinedReducer?.document?.learner_id,
    bil_list : combinedReducer?.bil?.bil_list,
    bil : combinedReducer?.bil?.bil,
    withDraw:combinedReducer?.withDraw?.with_draw,
    bil_count : combinedReducer?.bil?.bil_count,
  })
);

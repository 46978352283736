
import {
 OTHER_DOC ,
 INITIAL_ASSESSMENT ,
 COMPLIANCE_DOC, 
 DOCUMENT,
 LEARNER
  
  }  from "./action"; 
import { response } from "./types";
  
  const initialState : response = {
    otherDocs: [],
    initialDocs: [],
    complianceDocs: [],
    document_id : '',
    learner_id : ''
  };
  
  const document = (state = initialState, action:any) => {
    switch (action.type) {
     
      case OTHER_DOC:
        return {
          ...state,
          otherDocs: action.payload,
        };
      case INITIAL_ASSESSMENT:
        return {
          ...state,
          initialDocs: action.payload,
        };
      case COMPLIANCE_DOC:
        return {
          ...state,
          complianceDocs: action.payload,
        };
      case DOCUMENT : 
        return {...state , document_id : action.payload}
      case LEARNER : 
        return {...state , learner_id : action.payload}   
      default:
        return state;
    }
  };
  
  export default document;
  
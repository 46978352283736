import { complice_date, Iresponse } from "./types"

export const COMPLIANCE_DATE = 'COMPLIANCE_DATE' 
export const COMPLIANCE_RESPONSE = 'COMPLIANCE_RESPONSE'
export const COMPLIANCE_LIST = 'COMPLIANCE_LIST'

export const setComplianceDate = (complianceDate : complice_date) => ({
    type : COMPLIANCE_DATE,
    payload: complianceDate
})


export const setResponse = (complianceResponse : Iresponse) => ({
    type : COMPLIANCE_RESPONSE,
    payload : complianceResponse
})

export const setComplianceList = (complianceList : Iresponse) => ({
    type : COMPLIANCE_LIST,
    payload : complianceList
})
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable */
/* eslint-disable-next-line */

import { toast } from "react-toastify";
import { Dispatch, } from "redux";
import { setStoreSocketResponse } from "../redux/modules/request/action";
import { setBehavior, setKnowledge, setLearningPlan, setProgramData, setProgramList, setSkill, setStandardDetail } from "../redux/modules/admin/program/action";
import { setForm, setLearner, setLearnerSkillsSign, setUpdateLearner } from "../redux/modules/admin/learner/action";
import { setStoreContactDetails, setStoreContactList } from "../redux/modules/admin/contacts/action";
import { setNotificationList } from "../redux/modules/admin/notification/action";
import { setAdminList, setAdddressList, setConformationPop, setCreateAdminData, setGroupCreated, setGroupList, setManagerList, setTeachingStaffList, setIqaList, setEmployeeList, setEpaList, setRefererList, setLearnerList, setPostCodeList } from "../redux/modules/admin/users/action";
import App_url from "../Components/constants/static";
import { setProgressList } from "../redux/modules/admin/progress/action";
import { setAttendanceList } from "../redux/modules/admin/attendance/action";
import { setReview, setReviewList } from "../redux/modules/admin/review/action";
import { setOTJHList } from "../redux/modules/admin/otjh/action";
import { Behaviors, Knowledges, learningTotalPlanUnit, Skills } from "../redux/modules/admin/program/types";
import { setComplianceList, setResponse } from "../redux/modules/admin/complince/action";
import { setAuthData, setSign } from "../redux/modules/user_data/action";
import { setAddBill, setBil, setBilList } from "../redux/modules/admin/bil/action";
import { setDocCompliance, setDocInitialAssessment, setDocOther } from "../redux/modules/admin/documents/action";
import { setWithDraw } from "../redux/modules/admin/withdraw/action";


export const handleSocketResponse = async (
  data: any,
  dispatch: any,
  send: any,
  navigate?: any,
  type?: string,
  user_data?: any,
  access_token?: any,
) => (async (dispatch: Dispatch, getState: any) => {
  // const { requestLoader, modalLoader, PreviewPopup } = getState()?.combinedReducer?.uiReducer;
  const payloadRequest = {
    ...data,
    action: data?.request?.action,
    type: data?.request?.type,
    payload: data?.request?.payload,
  };
  const response = data?.response?.data
  console.log("socketres", data)
  dispatch(setStoreSocketResponse(payloadRequest));
  setTimeout(() => {
    dispatch(setStoreSocketResponse());
  }, 500);

  switch (data?.url) {
 

    case 'get_withdraw': {
      if (data?.response?.status === 200) {
        dispatch(setWithDraw(response))
      } else {
        dispatch(setWithDraw(response))
        // toast.error(data?.response?.msg || "An error occurred.");
      }
      break
    }


    case 'withdrawal_add': {
      if (data?.response?.status === 200) {
        toast.success(data?.response?.msg);
      } else {
        toast.error(data?.response?.msg || "An error occurred.");
      }

      break
    }

    case 'get_address_from_postal_code': {
      if (data?.response?.status === 200) {
        dispatch(setForm({ "url": data?.url, "status": data?.response?.status }))
        dispatch(setPostCodeList(response));
      } else {
        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }



    case 'create_compliance_docs': {
      if (data?.response?.status === 200) {
        dispatch(setResponse(response));
      } else {
        dispatch(setResponse(response));
        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }

    case 'bil_list': {
      if (data?.response?.status === 200) {
        dispatch(setBilList(response?.data));
      } else {
        dispatch(setBilList(null));
        // toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }

    case 'get_bil': {
      if (data?.response?.status === 200) {
        dispatch(setBil(response));
      } else {
        dispatch(setBil(null));
        dispatch(setForm({ "url":'get_bil', "status": 400 }))

        // toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }


    case 'learner_status': {
      if (data?.response?.status === 200) {
        dispatch(setForm({ "url": data?.url, "status": data?.response?.status }))
      } else {
        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }

    case 'get_compliance': {
      if (data?.response?.status === 200) {
        dispatch(setResponse(response[0]));
      } else {
        dispatch(setResponse(response[0]));
        // toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }
    case 'get_document': {
      if (data?.response?.status === 200) {
        switch (data?.request?.module_type) {
          case 'other':
            dispatch(setDocOther(response));
            break;

          case 'initial_assessment':
            dispatch(setDocInitialAssessment(response));
            break;

          case 'compliance':
            dispatch(setDocCompliance(response));
            break;

          default:
            console.warn(`Unhandled module_type: ${data?.request?.module_type}`);
        }
      } else {
        dispatch(setDocCompliance(''));
        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }


    case 'update_signature': {
      if (data?.response?.status === 200) {
        dispatch(setForm({ "url": data?.url, "status": data?.response?.status }))
      }
      break;
    }

    case 'compliance_list': {
      if (data?.response?.status === 200) {
        dispatch(setForm({ "url": data?.url, "status": data?.response?.status }))
        dispatch(setComplianceList(response?.data));
      } else {
        dispatch(setComplianceList(response?.data));
        // toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }

    case 'program_list': {
      if (data?.response?.status === 200) {
        dispatch(setProgramList(response))

      } else {
        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }

    case 'company_edit': {
      if (data?.response?.status === 200) {
        toast.success(data?.response?.msg)
      } else {
        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }

    case 'program_add': {
      if (data?.response?.status === 200) {
        toast.success(data?.response?.msg)
        dispatch(setForm({ "url": data?.url, "status": data?.response?.status }))
        dispatch(setProgramData(response))
        dispatch(setConformationPop(true))
        dispatch(setKnowledge({ knowledge: { title: "", topics: [] } }))
        dispatch(setSkill({ skill: { title: "", topics: [] } }))
        dispatch(setBehavior({ behavior: { title: "", topics: [] } }))
        const learningPlanWithTotal: learningTotalPlanUnit = {
          learningUnit: response?.learning_plan?.learningUnit,
          total_planned_otjh_hours: response?.learning_plan?.total_planned_otjh_hours,
        };
        dispatch(setLearningPlan(learningPlanWithTotal));
      } else {
        // toast.error("Failed to create Admin: " + data?.response?.msg || "An error occurred.");
      }
      break
    }
    case 'learner_add': {
      if (data?.response?.status === 200) {
        dispatch(setLearner(response))
        dispatch(setForm({ "url": data?.url, "status": data?.response?.status }))
        dispatch(setConformationPop(true))
      } else {
        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }
    case 'program_status': {
      if (data?.response?.status === 200) {
        dispatch(setForm({ "url": data?.url, "status": data?.response?.status }))
      }
      break;
    }
    case 'program_detail': {
      if (data?.response?.status === 200) {
        dispatch(setProgramData(response))
        dispatch(setStandardDetail({
          program_name: response?.program_name,
          program_type: response?.program_type,
          level: response?.level,
          end_point_assessment_organization_name: response?.end_point_assessment_organization_name,
          end_point_assessment_organization_number: response?.end_point_assessment_organization_number,
          complete_duration_period: response?.complete_duration_period,
          duration_epa_period: response?.duration_epa_period,
          duration_practical_period: response?.duration_practical_period,
          apprenticeship_standard_code: response?.apprenticeship_standard_code
        }));
        const skill: Skills = {
          skill: {
            title: response?.skill_scan?.skills?.skill?.title,
            topics: response?.skill_scan?.skills?.skill?.topics
          }
        }
        const knowledge: Knowledges = {
          knowledge: {
            title: response?.skill_scan?.knowledge?.knowledge?.title,
            topics: response?.skill_scan?.knowledge?.knowledge?.topics
          }
        }
        const behavior: Behaviors = {
          behavior: {
            title: response?.skill_scan?.behavior?.behavior?.title,
            topics: response?.skill_scan?.behavior?.behavior?.topics
          }
        }
        dispatch(setSkill(skill))
        dispatch(setKnowledge(knowledge))
        dispatch(setBehavior(behavior))
        const learningPlanWithTotal: learningTotalPlanUnit = {
          learningUnit: response?.learning_plan?.learningUnit,
          total_planned_otjh_hours: response?.learning_plan?.total_planned_otjh_hours,
        };
        // dispatch(setSkillScan(skillScanData))
        dispatch(setLearningPlan(learningPlanWithTotal));
      } else {
        // toast.error("Failed to create Admin: " + data?.response?.msg || "An error occurred.");
      }
      break;
    }

    case 'program_update': {
      if (data?.response?.status === 200) {
        toast.success(data?.response?.msg)
        dispatch(setForm({ "url": data?.url, "status": data?.response?.status }))
        dispatch(setProgramData(response))
        dispatch(setKnowledge({ knowledge: { title: "", topics: [] } }))
        dispatch(setSkill({ skill: { title: "", topics: [] } }))
        dispatch(setBehavior({ behavior: { title: "", topics: [] } }))
        const learningPlanWithTotal: learningTotalPlanUnit = {
          learningUnit: response?.learning_plan?.learningUnit,
          total_planned_otjh_hours: response?.learning_plan?.total_planned_otjh_hours,
        };
        dispatch(setLearningPlan(learningPlanWithTotal));
      } else {
        // toast.error("Failed to create Admin: " + data?.response?.msg || "An error occurred.");
      }
      break;
    }


    case 'learner_edit': {
      if (data?.response?.status === 200) {
        if (data?.module === "skill_scan" && data?.type) {
          toast.success(`Updated ${data?.type}'s skill scan successfully.`)
        } else {
          dispatch(setAuthData({ "user": response, "access_token": localStorage.getItem('access_token') }))
          toast.success(data?.response?.msg)
        }
        dispatch(setForm({ "url": data?.url, "status": data?.response?.status }))
        dispatch(setUpdateLearner(response))
      } else {
        if(data?.module === "skill_scan" && data?.type){
          toast.error(data?.response?.msg)
        }else{
        toast.success(data?.response?.msg)
        }
      }
      break;
    }
    case 'company_list': {
      if (data?.response?.status === 200) {
        dispatch(setEmployeeList(response))
      } else {
        dispatch(setEmployeeList(null))
        // toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }
    case 'company_add': {
      if (data?.response?.status === 200) {
        dispatch(setCreateAdminData(response));
        dispatch(setConformationPop(true))
      } else {
        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }
    case 'update_skill_signature': {
      if (data?.response?.status === 200) {
        toast.success(data?.response?.msg || "Signature added successfully.");
        dispatch(setLearnerSkillsSign(response))
      } else {
        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }

    case 'get_learner_details': {
      if (data?.response?.status === 200) {
        dispatch(setForm({ "url": data?.url, "status": data?.response?.status }))
        dispatch(setLearner(response));
        const skill: Skills = {
          skill: {
            title: response[0]?.initial_skill_scan?.skills?.skill?.title ?? response[0]?.mid_skill_scan?.skills?.skill?.title ?? response[0]?.final_skill_scan?.skills?.skill?.title,
            topics: response[0]?.initial_skill_scan?.skills?.skill?.topics ?? response[0]?.mid_skill_scan?.skills?.skill?.topics ?? response[0]?.final_skill_scan?.skills?.skill?.topics
          }
        }
        const knowledge: Knowledges = {
          knowledge: {
            title: response[0]?.initial_skill_scan?.knowledge?.knowledge?.title ?? response[0]?.mid_skill_scan?.knowledge?.knowledge?.title ?? response[0]?.final_skill_scan?.knowledge?.knowledge?.title,
            topics: response[0]?.initial_skill_scan?.knowledge?.knowledge?.topics ?? response[0]?.mid_skill_scan?.knowledge?.knowledge?.topics ?? response[0]?.final_skill_scan?.knowledge?.knowledge?.topics
          }
        }
        const behavior: Behaviors = {
          behavior: {
            title: response[0]?.initial_skill_scan?.behavior?.behavior?.title ?? response[0]?.mid_skill_scan?.behavior?.behavior?.title ?? response[0]?.final_skill_scan?.behavior?.behavior?.title,
            topics: response[0]?.initial_skill_scan?.behavior?.behavior?.topics ?? response[0]?.mid_skill_scan?.behavior?.behavior?.topics ?? response[0]?.final_skill_scan?.behavior?.behavior?.topics
          }
        }
        dispatch(setSkill(skill))
        dispatch(setKnowledge(knowledge))
        dispatch(setBehavior(behavior))
        const learningPlanWithTotal: learningTotalPlanUnit = {
          learningUnit: response[0]?.learning_plan?.learningUnit,
          total_planned_otjh_hours: response[0]?.learning_plan?.total_planned_otjh_hours,
        };
        dispatch(setLearningPlan(learningPlanWithTotal));
      } else {
        // toast.error("Failed to create Admin: " + data?.response?.msg || "An error occurred.");
      }
      break;
    }



    case 'get_company': {
      if (data?.response?.status === 200) {
        dispatch(setCreateAdminData(response));
      } else {

        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }
    case 'user_list': {
      if (data?.response?.status === 200) {
        switch (data?.request?.user_type) {
          case 'admin': {
            dispatch(setAdminList(response))
            // dispatch(setSign(response?.signature?true:false))
            break;
          }
          case 'tutor':

            dispatch(setTeachingStaffList(response));
            // dispatch(setSign(response?.signature?true:false))

            break;

          case 'iqa':

            dispatch(setIqaList(response));
            // dispatch(setSign(response?.signature?true:false))

            break;

          case 'employer':

            dispatch(setEmployeeList(response));
            // dispatch(setSign(response?.signature?true:false))

            break;

          case 'epa':

            dispatch(setEpaList(response));
            // dispatch(setSign(response?.signature?true:false))

            break;

          case 'referrer':

            dispatch(setRefererList(response));
            // dispatch(setSign(response?.signature?true:false))

            break;
          case 'learner': {
            dispatch(setLearnerList(response))
            // dispatch(setSign(response?.signature?true:false))

            break;
          }
          case 'manager': {
            dispatch(setManagerList(response))
            // dispatch(setSign(response?.signature?true:false))

            break;
          }
          default:
            break;
        }

      } else {
        switch (data?.request?.user_type) {
          case 'admin': {

            dispatch(setAdminList(response))
            break;
          }
          case 'tutor': {
            dispatch(setTeachingStaffList(response))
            break;
          }
          case 'iqa': {
            dispatch(setIqaList())
            break;
          }
          case 'employer': {
            dispatch(setEmployeeList())
            break;
          }
          case 'epa': {
            dispatch(setEpaList())
            break;
          }
          case 'referrer': {
            dispatch(setRefererList())
            break;
          }
          case 'learner': {
            dispatch(setLearnerList())
            break;
          }
          case 'manager': {
            dispatch(setManagerList())
            break;
          }
          default:
            break;
        }
        // toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }
    case 'contact_list': {
      if (data?.response?.status === 200) {
        dispatch(setStoreContactList(response))
      } else {
        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }
    case 'notification_list': {
      if (data?.response?.status === 200) {
        dispatch(
          setNotificationList({ ...data?.response?.data })
        );
      } else {
        dispatch(setNotificationList({
          data: [],
          pagination: {
            current_page: 0,
            record_limit: 0,
            total_records: 0,
          },
        }));
        toast.error(response?.msg || "An error occurred.");
      }
      break;
    }
    case 'notification_update': {
      if (data?.response?.status === 200) {
        toast.success(data?.response?.msg);
      } else {
        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }
    case 'notification_received': {
      toast.success(data?.response?.msg, {
        onClick: () => {
          window.location.href = App_url?.link?.Notifications;
        },
      });
      break;
    }
    case 'progress_list': {
      if (data?.response?.status === 200) {
        dispatch(
          setProgressList({ ...data?.response?.data })
        );
      } else {
        dispatch(setProgressList({
          data: [],
          pagination: {
            current_page: 0,
            record_limit: 0,
            total_records: 0,
          },
        }));
        toast.error(response?.msg || "An error occurred.");
      }
      break;
    }
    case 'attendance_list': {
      if (data?.response?.status === 200) {
        dispatch(
          setAttendanceList({ ...data?.response?.data })
        );
      } else {
        dispatch(setAttendanceList({
          data: [],
          pagination: {
            current_page: 0,
            record_limit: 0,
            total_records: 0,
          },
        }));
        toast.error(response?.msg || "An error occurred.");
      }
      break;
    }
    case 'lecture_summary_list': {
      if (data?.response?.status === 200) {
        dispatch(
          setAttendanceList({ ...data?.response?.data })
        );
      } else {
        dispatch(setAttendanceList({
          data: [],
          pagination: {
            current_page: 0,
            record_limit: 0,
            total_records: 0,
          },
        }));
        toast.error(response?.msg || "An error occurred.");
      }
      break;
    }
    case 'review_add': {
      if (data?.response?.status === 200) {
        console.log("response" , response)
        toast.success(data?.response?.msg);
      } else {
        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }

    case 'review_get': {
      if (data?.response?.status === 200) {
        dispatch(setReview(response))
        dispatch(setForm({ "url": 'review_get', "status": 200 }))
        // toast.success(data?.response?.msg);
      } else {
        dispatch(setReview(response))
        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }

    case 'update_review_signature': {
      if (data?.response?.status === 200) {
        dispatch(setForm({ "url": "update_review_signature", "status": 200 }))
      } 
      break;
    }
    case 'review_update': {
      if (data?.response?.status === 200) {
        toast.success(data?.response?.msg);
      } else {
        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }
    case 'review_delete': {
      if (data?.response?.status === 200) {
        toast.success(data?.response?.msg);
      } else {
        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }
    case 'review_list': {
      if (data?.response?.status === 200) {
        dispatch(
          setReviewList({ ...data?.response?.data })
        );
      } else {
        dispatch(setReviewList({
          data: [],
          pagination: {
            current_page: 0,
            record_limit: 0,
            total_records: 0,
          },
        }));
        toast.error(response?.msg || "An error occurred.");
      }
      break;
    }
    case 'otjh_record_list': {
      if (data?.response?.status === 200) {
        dispatch(
          setOTJHList({ ...data?.response?.data })
        );
      } else {
        dispatch(setOTJHList({
          data: [],
          pagination: {
            current_page: 0,
            record_limit: 0,
            total_records: 0,
          },
        }));
        toast.error(response?.msg || "An error occurred.");
      }
      break;
    }
    case 'otjh_record_add': {
      if (data?.response?.status === 200) {
        toast.success(data?.response?.msg);
      } else {
        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }
    case 'otjh_record_update': {
      if (data?.response?.status === 200) {
        toast.success(data?.response?.msg);
      } else {
        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }
    case 'otjh_record_delete': {
      if (data?.response?.status === 200) {
        toast.success(data?.response?.msg);
      } else {
        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }
    case 'update_otjh_signature': {
      if (data?.response?.status === 200) {
        toast.success(data?.response?.msg);
      } else {
        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }
    case 'bulk_progress_add': {
      if (data?.response?.status === 200) {
        toast.success(data?.response?.msg);
      } else {
        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }
    case 'contact_add': {
      if (data?.response?.status === 200) {
        toast.success(data?.response?.msg);
      } else {
        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }
    case 'notification_delete': {
      if (data?.response?.status === 200) {
        toast.success(data?.response?.msg);
      } else {
        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }
    case 'contact_update': {
      if (data?.response?.status === 200) {
        toast.success(data?.response?.msg);
      } else {
        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }
    case 'contact_reply': {
      if (data?.response?.status === 200) {
        toast.success(data?.response?.msg || "Reply send success.");
        dispatch(setStoreContactDetails(response))
      } else {
        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }
    case 'bulk_attendance_save': {
      if (data?.response?.status === 200) {
        toast.success(data?.response?.msg);
      } else {
        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }
    case 'bulk_attendance_update': {
      if (data?.response?.status === 200) {
        toast.success(data?.response?.msg);
      } else {
        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }
    case 'delete_attendance_by_lecture': {
      if (data?.response?.status === 200) {
        toast.success(data?.response?.msg);
      } else {
        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }
    case 'attendance_add': {
      if (data?.response?.status === 200) {
        toast.success(data?.response?.msg);
      } else {
        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }
    case 'attendance_update': {
      if (data?.response?.status === 200) {
        toast.success(data?.response?.msg);
      } else {
        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }
    case 'group_add': {
      if (data?.response?.status === 200) {
        dispatch(setGroupCreated(response));
        dispatch(setConformationPop(true))
      } else {
        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }
    case 'group_list': {
      if (data?.response?.status === 200) {
        dispatch(setGroupList(response))
      } else {
        dispatch(setGroupList([]))
      }
      break
    }
    case 'group_update': {
      if (data?.response?.status === 200) {
        toast.success(data?.response?.msg)
      } else {
        toast.error(data?.response?.msg)
      }
      break;
    }
    case 'bil_add': {
      if (data?.response?.status === 200) {
        toast.success(data?.response?.msg);
        dispatch(setAddBill(response))
      } else {
        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }
    case 'bil_edit': {
      if (data?.response?.status === 200) {
        toast.success(data?.response?.msg);
      } else {
        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }
    case 'user_add': {
      if (data?.response?.status === 200) {
        if (data?.response?.data?.user_type) {
          dispatch(setCreateAdminData(response));
          dispatch(setForm({ "url": data?.url, "status": data?.response?.status }))
          dispatch(setConformationPop(true))
        }

      } else {
        toast.error( data?.response?.msg || "An error occurred.");
      }
      break;
    }
    case 'manager_list': {

      if (data?.response?.status === 200) {
        dispatch(setManagerList(response?.data));
      } else {

      }
      break;
    }
    case 'address_list': {

      if (data?.response?.status === 200) {
        dispatch(setAdddressList(response?.data));
      } else {

      }
      break;
    }
    case 'get_user_details': {
      if (data?.response?.status === 200) {
        console.log("response", response)
        if (data?.response?.data?.user_type === "admin") {
          dispatch(setCreateAdminData(response));
        }
        else if (data?.response?.data?.user_type === "employer") {
          dispatch(setCreateAdminData(response));
        }
        else if (data?.response?.data?.user_type === "tutor") {
          dispatch(setCreateAdminData(response));
        }
        else if (data?.response?.data?.user_type === "iqa") {
          dispatch(setCreateAdminData(response));
        }
        else if (data?.response?.data?.user_type === "epa") {
          dispatch(setCreateAdminData(response));
        }
        else if (data?.response?.data?.user_type === "referrer") {
          dispatch(setCreateAdminData(response));
        }
        else if (data?.response?.data?.user_type === "manager") {
          dispatch(setCreateAdminData(response));
        }
      } else {
        toast.error(data?.response?.msg);
      }
      break;
    }
    case 'user_edit': {
      if (data?.response?.status === 200) {
        // dispatch(setUpdateUserLogin(true))
        dispatch(setAuthData({ "user": response, "access_token": localStorage.getItem('access_token') }))
        toast.success("User Updated Successfully")
      } else {
        toast.error(data?.response?.msg)
      }
      break;
    }
    case 'unauthorized': {
      toast.error(data?.response?.msg)
      localStorage.clear()
      window.location.href = App_url?.link?.Login;
      window.location.reload()
      break;
    }

    default:
      break;
  }
})
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable */
/* eslint-disable-next-line */

import {  Behaviors, diploma, FeeExposure, functional_skill, Knowledges, LearningPlanUnit, learningTotalPlanUnit, ProgramData, ProgramListRequest, ProgramResponse,  Skills,  SkillScan, StandardDetail } from "./types";
export const SET_PROGRAM_LIST = 'SET_PROGRAM_LIST';
export const SET_PROGRAM_DATA = "SET_PROGRAM_DATA";
export const SET_STANDARD_DETAIL = "SET_STANDARD_DETAIL";
export const SET_FEE_STRUCTURE = "SET_FEE_STRUCTURE";
export const SET_LEARNING_PLAN = 'SET_LEARNING_PLAN';
export const SET_SKILL_SCAN = 'SET_SKILL_SCAN'
export const SET_DIPLOMA = 'SET_DIPLOMA'
export const SET_FUNCTIONAL_SKILL = 'SET_FUNCTIONAL_SKILL'
export const SET_CLEAR_VALUE = 'SET_CLEAR_VALUE'
export const SET_THE_CLONE_PROGRAM = 'SET_THE_CLONE_PROGRAM'
export const UPDATE_SKILL_SCAN = 'UPDATE_SKILL_SCAN'
export const SET_SKILL = 'SET_SKILL'
export const SET_KNOWLEDGE = 'SET_KNOWLEDGE'
export const SET_BEHAVIOR = 'SET_BEHAVIOR'
export const COMPLETE_DATA='COMPLETE_DATA'
 
// export const SET_LEARNER_PLAN = 'SET_LEARNER_PLAN'

export const setCompleteData=(complete_programList : ProgramResponse)=>({
    type:COMPLETE_DATA,
    payload:complete_programList
})
export const setClearValue = () => ({
    type: SET_CLEAR_VALUE,
    // payload: programList
})

export const setProgramList = (programList : ProgramResponse) => ({
    type: SET_PROGRAM_LIST,
    payload: programList
})

export const setSkillScan = (programList :  Partial<SkillScan>) => ({
    type: SET_SKILL_SCAN,
    payload: programList
})

export const updateSkillScan = (programList : SkillScan) => ({
    type: UPDATE_SKILL_SCAN,
    payload: programList
})

export const setProgramData = (programData: ProgramResponse) => ({
  type: SET_PROGRAM_DATA,
  payload: programData
});

export const setStandardDetail = (standardDetail : StandardDetail) => ({
    type: SET_STANDARD_DETAIL,
    payload: standardDetail
})

export const setFeeStructure = (feeStructure : FeeExposure[]) => ({
    type: SET_FEE_STRUCTURE,
    payload: feeStructure
})


export const setLearningPlan = (learningPlan: learningTotalPlanUnit) => ({
    type: SET_LEARNING_PLAN,
    payload: learningPlan,
});

export const setDiploma = (diploma : diploma) => ({
    // type: SET_LEARNING_PLAN,
    type: SET_DIPLOMA,
    payload: diploma,
});

export const setFunctional = (functional_skill : functional_skill) => ({
    type: SET_FUNCTIONAL_SKILL,
    payload: functional_skill,
});

export const setCloneProgram = (cloneProgram : ProgramResponse) => ({
    type: SET_THE_CLONE_PROGRAM,
    payload: cloneProgram,
});

export const setSkill = (skill : Partial<Skills>) => ({
    type: SET_SKILL,
    payload: skill,
})

export const setKnowledge = (knowledge : Knowledges) => ({
    type: SET_KNOWLEDGE,
    payload: knowledge,
})

export const setBehavior = (behavior : Behaviors) => ({
    type: SET_BEHAVIOR,
    payload: behavior,
})






import { PROOF_OF_EMPLOYMENT, PROOF_OF_ID, PROOF_OF_QUALIFICATION, REMOVE_ENGLISH_RESULT, REMOVE_MATH_RESULT, SET_ENGLISH_RESULT, SET_IGA_REPORT, SET_MATH_RESULT, SET_PLR_REPORT, SET_REMOVE_IGA_REPORT, SET_REMOVE_PLR_REPORT, SET_REMOVE_PROOF_EMPLOYMENT, SET_REMOVE_PROOF_ID, SET_REMOVE_QUALIFICATION, SET_UPDATE_ENGLISH_RESULT , BIL_LEARNING_EVIDENCE , BIL_REMOVE_LEARNING_EVIDENCE, BIL_REMOVE} from "./action";
import { Fileupload } from "./types";


const initialState: Fileupload = {
    math_result: [],
    english_result: [],
    plr_result: [],
    iag_report: [],
    proof_of_id: [],
    proof_of_employment: [],
    proof_of_qualification: [],
    bil_learning_evidence : [],
    remove_bil:String,
};

const fileUploadReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case SET_MATH_RESULT:
          
            if (action.payload === null) {
                return { ...state, math_result: [] };
              }

              const isAlreadyPresent = state.math_result.some(
                (item:any) => item.id === action.payload.id
            );
        // if(action.payload===)
            if (isAlreadyPresent) {
                return state; 
            }
            return { ...state, math_result: [...state?.math_result, action.payload] };

        case REMOVE_MATH_RESULT:
                return {
                    ...state,
                    math_result: state.math_result.filter(
                        (item: any) => item.id !== action.payload.id
                    ),
                };
        
        
        case SET_ENGLISH_RESULT:
            if (action.payload === null) {
                return { ...state, english_result: [] };
              }
            const isAlreadyPresentEnslish = state.english_result.some(
                (item:any) => item.id === action.payload.id
            );
        
            if (isAlreadyPresentEnslish) {
                return state; 
            }
            return { ...state, english_result: [...state?.english_result,action.payload] };
        
        case REMOVE_ENGLISH_RESULT:
            return {
                ...state,
                english_result: state.english_result.filter(
                    (item: any) => item.id !== action.payload.id
                ),
            };
        case SET_UPDATE_ENGLISH_RESULT:
            if (!Array.isArray(state.english_result)) {
                return state; 
            }
            return {
                ...state,
                english_result: [...state.english_result, action.payload],
            };


        case SET_IGA_REPORT:
            if (action.payload === null) {
                return { ...state, iag_report: [] };
              }
              const isAlreadyPresentIGA = state.iag_report.some(
                (item:any) => item.id === action.payload.id
            );
        
            if (isAlreadyPresentIGA) {
                return state; 
            }
            return { ...state, iag_report: [...state?.iag_report, action.payload] };
        case SET_REMOVE_IGA_REPORT:
                return {
                    ...state,
                    iag_report: state.iag_report.filter(
                        (item: any) => item.id !== action.payload.id
                    ),
                };
        case SET_REMOVE_PLR_REPORT:
                    return {
                        ...state,
                        plr_result: state.plr_result.filter(
                            (item: any) => item.id !== action.payload.id
                        ),
                    };
        case SET_PLR_REPORT:
            if (action.payload === null) {
                return { ...state, plr_result: [] };
              }
              const isAlreadyPresentPLR = state.plr_result.some(
                (item:any) => item.id === action.payload.id
            );
        
            if (isAlreadyPresentPLR) {
                return state; 
            }
            return { ...state, plr_result: [...state?.plr_result, action.payload] };
        case PROOF_OF_ID:
            if (action.payload === null) {
                return { ...state, proof_of_id: [] };
              }
              const isAlreadyPresentProofOfId = state.proof_of_id.some(
                (item:any) => item.id === action.payload.id
            );
        
            if (isAlreadyPresentProofOfId) {
                return state; 
            }
            return { ...state, proof_of_id: [...state?.proof_of_id, action.payload] };
        case SET_REMOVE_PROOF_ID:
                return {
                    ...state,
                    proof_of_id: state.proof_of_id.filter(
                        (item: any) => item.id !== action.payload.id
                    ),
                };
        case SET_REMOVE_PROOF_EMPLOYMENT:
                    return {
                        ...state,
                        proof_of_employment: state.proof_of_employment.filter(
                            (item: any) => item.id !== action.payload.id
                        ),
                    };
        case SET_REMOVE_QUALIFICATION:
            return {
                ...state,
                proof_of_qualification: state.proof_of_qualification.filter(
                    (item: any) => item.id !== action.payload.id
                ),
            };
            
        case BIL_REMOVE_LEARNING_EVIDENCE:
            return {
                ...state,
                bil_learning_evidence: state.bil_learning_evidence.filter(
                    (item: any) => item.id !== action.payload.id
                ),
            };
            
            
        case PROOF_OF_EMPLOYMENT:
            if (action.payload === null) {
                return { ...state, proof_of_employment: [] };
              }
              const isAlreadyPresentProofOfEmp = state.proof_of_employment.some(
                (item:any) => item.id === action.payload.id
            );
        
            if (isAlreadyPresentProofOfEmp) {
                return state; 
            }
            return { ...state, proof_of_employment: [...state?.proof_of_employment, action.payload] };


        case PROOF_OF_QUALIFICATION:
            if (action.payload === null) {
                return { ...state, proof_of_qualification: [] };
              }
              const isAlreadyPresentProofOfQua = state.proof_of_qualification.some(
                (item:any) => item.id === action.payload.id
            );
        
            if (isAlreadyPresentProofOfQua) {
                return state; 
            }
            return { ...state, proof_of_qualification: [...state?.proof_of_qualification, action.payload] };
            

            case BIL_LEARNING_EVIDENCE:
                if (action.payload === null ||action.payload ==="") {
                   
                    return { ...state, bil_learning_evidence: [] };
                  }
                  const isAlreadyPresentBilEvidance = state.bil_learning_evidence.some(
                    (item:any) => item.id === action.payload.id
                );
            
                if (isAlreadyPresentBilEvidance) {
                    return state; 
                }
                return { ...state, bil_learning_evidence: [...state?.bil_learning_evidence, action.payload] }; 
            case BIL_REMOVE : 
                    return { ...state , remove_bil : action.payload}      
        default:
            return state;
    }
};

export default fileUploadReducer;

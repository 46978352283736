import { ActionTypes } from "./action";

interface UserState {
  company:object| null,
  userCreated: object | null;
  admin_list: [];
  complete_admin_list:[],
  teaching_staff_list: [],
  complete_teaching_staff_list: [],
  manager_list: [],
  address_list: [],
  group_list: [],
  employee_list:[],
  complete_employee_list:[],
  iqa_list:[],
  complete_iqa_list:[],
  epa_list:[],
  complete_epa_list:[],
  referrer_list:[],
  complete_referrer_list:[],
  complete_group_list: [],
  learner_list:[],
  group: object | null,
  ConfirmationPopup: boolean;
  Search: string;
  postcode:[],
}

const initialState: UserState = {
  company:null,
  userCreated: null,
  admin_list: [],
  complete_admin_list:[],
  teaching_staff_list: [],
  complete_teaching_staff_list: [],
  employee_list:[],
  complete_employee_list:[],
  iqa_list:[],
  complete_iqa_list:[],
  epa_list:[],
  complete_epa_list:[],
  referrer_list:[],
  complete_referrer_list:[],
  manager_list: [],
  address_list: [],
  group_list: [],
  complete_group_list: [],
  learner_list:[],
  group: null,
  ConfirmationPopup: false,
  Search: '',
  postcode:[],

};

export const userReducer = (state = initialState, action: any): UserState => {
  switch (action.type) {
    case ActionTypes.COMPLETE_GET_ADMIN_LIST:{
      return { ...state, complete_admin_list: action.payload || initialState?.complete_admin_list };
    }
    case ActionTypes.COMPLETE_GET_EMPLOYEE_LIST:{
      return { ...state, complete_employee_list: action.payload || initialState?.complete_employee_list };
    }
    case ActionTypes.COMPLETE_GET_EPA_LIST:{
      return { ...state, complete_epa_list: action.payload || initialState?.complete_epa_list };
    }
    case ActionTypes.COMPLETE_GET_IQA_LIST:{
      return { ...state, complete_iqa_list: action.payload || initialState?.complete_iqa_list };
    }
    case ActionTypes.COMPLETE_GET_REFERER_LIST:{
      return { ...state, complete_referrer_list: action.payload || initialState?.complete_referrer_list };
    }
    case ActionTypes.COMPLETE_GET_TEACHINGSTAFF_LIST:{
      return { ...state, complete_teaching_staff_list: action.payload || initialState?.complete_teaching_staff_list };
    }
    case ActionTypes.SEND_USER_DATA: {
      return { ...state, userCreated: action.payload || initialState?.userCreated };
    }
    case ActionTypes.GET_ADMIN_LIST: {
      return { ...state, admin_list: action.payload || initialState?.admin_list };
    }
    case ActionTypes.GET_EMPLOYEE_LIST: {
      return { ...state, employee_list: action.payload || initialState?.employee_list };
    }
    case ActionTypes.GET_REFERER_LIST: {
      return { ...state, referrer_list: action.payload || initialState?.referrer_list };
    }
    case ActionTypes.GET_EPA_LIST: {
      return { ...state, epa_list: action.payload || initialState?.epa_list };
    }
    case ActionTypes.GET_IQA_LIST: {
      return { ...state, iqa_list: action.payload || initialState?.iqa_list};
    }
    case ActionTypes.GET_TEACHINGSTAFF_LIST: {
      return { ...state, teaching_staff_list: action.payload || initialState?.teaching_staff_list };
    }
    case ActionTypes.GET_LEARNER_LIST: {
      return { ...state, learner_list: action.payload || initialState?.learner_list };
    }
    case ActionTypes.SHOW_CONFORMTION_POP: {
      return { ...state, ConfirmationPopup: action.payload || initialState?.ConfirmationPopup };
    }
    case ActionTypes.GET_MANAGER_LIST: {
      return { ...state, manager_list: action.payload || initialState?.manager_list };
    }
    case ActionTypes.GET_ADDRESS_LIST: {
      return { ...state, address_list: action.payload || initialState?.address_list };
    }
    case ActionTypes.Search: {
      return { ...state, Search: action.payload || initialState?.Search };
    }
    case ActionTypes.GET_GROUP_LIST: {
      return { ...state, group_list: action.payload || initialState?.group_list }
    }
    case ActionTypes.COMPLETE_GET_GROUP_LIST: {
      return { ...state, complete_group_list: action.payload || initialState?.group_list }
    }
    case ActionTypes.GROUP_CREATED: {
      return { ...state, group: action.payload || initialState?.group }
    }
    case ActionTypes.POST_CODE: {
      return { ...state, postcode: action.payload || initialState?.postcode }
    }
    case ActionTypes.GET_COMPANY: {
      return { ...state, company: action.payload || initialState?.company }
    }
    default:
      return state;
  }
};

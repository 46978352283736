import {
  LEARNER_ERROR,
  LEARNER_LOADING,
  SET_LEARNER,
  SET_LEARNER_LIST,
  LEARNER_DETAILS,
  SET_UPDATE_LEARNER,
  RESET_FORM,
  SET_LEARNER_SKILL_SIGN,
} from "./action";
import { LearnerState } from "./types";

const initialState: LearnerState = {
  learnerData: null,
  loading: false,
  learner: [],
  error: null,
  learnerDetails: [],
  learnerForm: null,
};

const learnerReducer = (state = initialState, action: any): LearnerState => {
  switch (action.type) {
    case RESET_FORM:
      return {
        ...state,
        learnerForm: action.payload,
        loading: false,
        error: null,
      };

    case SET_LEARNER:
      return {
        ...state,
        learner: action.payload,
        loading: false,
        error: null,
      };

    case SET_LEARNER_SKILL_SIGN:
      const updatedLearner = state.learner.map(
        (learnerItem: any, index: any) => {
          const payload = action.payload?.[index] || {};
          return {
            ...learnerItem,
            final_skill_scan: {
              ...learnerItem?.final_skill_scan,
              skill_scan_employer_signature:
                payload.final_skill_scan?.skill_scan_employer_signature ??
                learnerItem?.final_skill_scan?.skill_scan_employer_signature,
              skill_scan_learner_signature:
                payload.final_skill_scan?.skill_scan_learner_signature ??
                learnerItem?.final_skill_scan?.skill_scan_learner_signature,
              skill_scan_admin_signature:
                payload.final_skill_scan?.skill_scan_admin_signature ??
                learnerItem?.final_skill_scan?.skill_scan_admin_signature,
              learner_skill_date:
                payload.final_skill_scan?.learner_skill_date ??
                learnerItem?.final_skill_scan?.learner_skill_date,
              admin_skill_date:
                payload.final_skill_scan?.admin_skill_date ??
                learnerItem?.final_skill_scan?.admin_skill_date,
              employer_skill_date:
                payload.final_skill_scan?.employer_skill_date ??
                learnerItem?.final_skill_scan?.employer_skill_date,
              verified:
                payload.final_skill_scan?.verified ??
                learnerItem?.final_skill_scan?.verified,
              is_complete:
                payload.final_skill_scan?.is_complete ??
                learnerItem?.final_skill_scan?.is_complete,
            },
            mid_skill_scan: {
              ...learnerItem?.mid_skill_scan,
              skill_scan_employer_signature:
                payload.mid_skill_scan?.skill_scan_employer_signature ??
                learnerItem?.mid_skill_scan?.skill_scan_employer_signature,
              skill_scan_learner_signature:
                payload.mid_skill_scan?.skill_scan_learner_signature ??
                learnerItem?.mid_skill_scan?.skill_scan_learner_signature,
              skill_scan_admin_signature:
                payload.mid_skill_scan?.skill_scan_admin_signature ??
                learnerItem?.mid_skill_scan?.skill_scan_admin_signature,
              learner_skill_date:
                payload.mid_skill_scan?.learner_skill_date ??
                learnerItem?.mid_skill_scan?.learner_skill_date,
              admin_skill_date:
                payload.mid_skill_scan?.admin_skill_date ??
                learnerItem?.mid_skill_scan?.admin_skill_date,
              employer_skill_date:
                payload.mid_skill_scan?.employer_skill_date ??
                learnerItem?.mid_skill_scan?.employer_skill_date,
              verified:
                payload.final_skill_scan?.verified ??
                learnerItem?.final_skill_scan?.verified,
              is_complete:
                payload.final_skill_scan?.is_complete ??
                learnerItem?.final_skill_scan?.is_complete,
            },
            initial_skill_scan: {
              ...learnerItem?.initial_skill_scan,
              skill_scan_employer_signature:
                payload.initial_skill_scan?.skill_scan_employer_signature ??
                learnerItem?.initial_skill_scan?.skill_scan_employer_signature,
              skill_scan_learner_signature:
                payload.initial_skill_scan?.skill_scan_learner_signature ??
                learnerItem?.initial_skill_scan?.skill_scan_learner_signature,
              skill_scan_admin_signature:
                payload.initial_skill_scan?.skill_scan_admin_signature ??
                learnerItem?.initial_skill_scan?.skill_scan_admin_signature,
              learner_skill_date:
                payload.initial_skill_scan?.learner_skill_date ??
                learnerItem?.initial_skill_scan?.learner_skill_date,
              admin_skill_date:
                payload.initial_skill_scan?.admin_skill_date ??
                learnerItem?.initial_skill_scan?.admin_skill_date,
              employer_skill_date:
                payload.initial_skill_scan?.employer_skill_date ??
                learnerItem?.initial_skill_scan?.employer_skill_date,
              verified:
                payload.final_skill_scan?.verified ??
                learnerItem?.final_skill_scan?.verified,
              is_complete:
                payload.final_skill_scan?.is_complete ??
                learnerItem?.final_skill_scan?.is_complete,
            },
          };
        }
      );
      return {
        ...state,
        learner: updatedLearner,
        loading: false,
        error: null,
      };

    case SET_LEARNER_LIST:
      return {
        ...state,
        learnerData: action.payload,
        loading: false,
        error: null,
      };

    case LEARNER_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case LEARNER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case LEARNER_DETAILS:
      return {
        ...state,
        learnerDetails: action.payload,
        loading: false,
        error: action.payload,
      };

    case SET_UPDATE_LEARNER:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export default learnerReducer;

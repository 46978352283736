
import { GET_WITHDRAW } from "./action";
import { WithDrawState } from "./types";

const initialState: WithDrawState = {
    loading: false,
    error: null,
    with_draw : [],
};

const withDrawReducer = (state = initialState, action: any): WithDrawState => {
   switch (action.type) {
     case GET_WITHDRAW : 
         return { ...state , with_draw : action.payload}     
        default:
          return state;
  }
};

export default withDrawReducer;

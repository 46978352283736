/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { usePosterReducers } from "../../usePoster";
import { ActionTypes } from "./action";
import { IUserDataAction, IUserRes } from "./types";


const initialState: IUserRes = {
    user: {
      emp_id:"",
      full_name: "",
      user_type: "",
      is_active: false,
      image: "",
      honorific: "",
      first_name: "",
      last_name: "",
      email: "",
      country_code: "",
      mobile_number: "",
      job_title: "",
      gender: "",
      signature: "",
      no_of_staff: "",
      telephone_number: "",
      levy_type: "",
      company_number: "",
      paye_number: "",
      edrn_number: "",
      employer_size: "",
      iqa_name: "",
      register_number: "",
      company_name: "",
      deleted_at: "",
      created_at : "", 
      updated_at : "",
      id: "",
      user_code:"",
      status : '',
      is_send_withdrawal:false,
    },
    access_token: "",
    loading: false,
    sign:false
};

const userDataReducers = ( state: IUserRes = initialState, action: any ) => {
  switch (action.type) {
    case ActionTypes.AUTH_DATA: {
      if (state.user.id && state.user.id === action.payload.user.id) {
        return {
          ...state,
          ...action.payload, // Update state with the new payload
        };
      }
      if (!state.user.id) {
        return {
          ...state,
          ...action.payload,
        };
      }
     return state;
    }

    case ActionTypes.IS_LOGIN: {
      return {
        ...state,
        is_Login:action.payload,
      };
    }
    case ActionTypes.SET_UPDATE_USER_LOGIN: {
      return {
        ...state,
        user:{
          ...state?.user,
          is_reset: action?.payload?.is_reset
        }
        // is_Login:action.payload,
      };
    }
    case ActionTypes.SET_STORE_LOADER: { // New case for managing loading state
      return {
        ...state,
        loading: action.payload, // Update the loading state
      };
    }
    case ActionTypes.SET_SIGN: { // New case for managing loading state
      return {
        ...state,
        sign: action.payload, // Update the loading state
      };
    }
    case ActionTypes.ADMIN_LOGOUT:{
      return initialState
    }
    default:
      return state;
  }

};

export default userDataReducers;
